
<template>
    <div>
        <select
            class="form-control"
            id="inspectionRouteSelector"
            multiple="multiple"
        >
            <option value="">请选择巡检路线</option>
        </select>
    </div>
</template>
<script>
const OWNER_TYPE = {
    OWNER: "1001",
    TENANT: "1003",
};
// 考核类型
const ASSESSMENT_TYPE = {
    BUSINESS: 2,
    COMMON: 1,
    SYSTEM: 3,
};

// 删除时的类型
const DELETE_TYPE = {
    TABLE: 1, // 删除通用考核表
    BUSINESS_ITEM: 2, // 删除业务考核项
    COMMON_ITEM: 3, // 删除通用考核项
};
// 考核人员
const ASSESSMENT_OBJECT_TYPE = {
    STAFF: 1,
    SUPERVISOR: 2,
    MANAGER: 3,
};

const AUTO_ASSESSMENT_TYPE = {
    INSPECTION: 1,
    WORK_ORDER: 2,
    ATTENDANCE: 3,
};

// 考核项目
const ASSESSMENT_ITEM_TYPE = {
    COMMON_ITEM: 1,
    BUSINESS_ITEM: 2,
};

const DEFAULT_PAGE = 1;
const DEFAULT_ROWS = 10;

export default {
    props: {
        parentModal: string,
        callBackListener: String,
        callBackFunction: String,
    },
    components: {},
    data() {
        return {
            inspectionRouteSelect2Info: {
                inspectionRoutes: [],
                inspectionRouteId: "",
                routeName: "",
                inspectionRouteSelector: {},
            },
        };
    },
    watch: {
        inspectionRouteSelect2Info: {
            deep: true,
            handler: function () {
                this.$this.$vc.emit(this.$route.path,
                    this.$this.callBackListener,
                    this.$this.callBackFunction,
                    this.inspectionRouteSelect2Info
                );
            },
        },
    },
    mounted() {
        (() => {
            this._initInspectionRouteSelect2();
        })();
        (() => {
            this.$vc.on(this.$route.path,
                "inspectionRouteSelect2",
                "setInspectionRoute",
                (_param) => {
                    this.$vc.copyObject(
                        _param,
                        this.inspectionRouteSelect2Info
                    );
                    $("#inspectionRouteSelector").val(null).trigger("change");
                    var name = _param.routeName.split(",");
                    var ids = _param.inspectionRouteId.split(",");
                    for (var i = 0; i < name.length; i++) {
                        var option = new Option(name[i], ids[i], true, true);
                        console.log(option);
                        this.inspectionRouteSelect2Info.inspectionRouteSelector.append(
                            option
                        );
                    }
                    //var option = new Option(_param.routeName, _param.inspectionRouteId, true, true);

                    // var routeIds = _param.inspectionRouteId.split(",");
                    // var routeIdArray = new Array()
                    // for( var i = 0; i < routeIds.length; i++){
                    //     routeIdArray[i] = routeIds[i];
                    // }
                    // $("#inspectionRouteSelector").val(routeIdArray).trigger("change");
                }
            );

            this.$vc.on(this.$route.path,
                "inspectionRouteSelect2",
                "clearInspectionRoute",
                (_param) => {
                    this.inspectionRouteSelect2Info = {
                        inspectionRoutes: [],
                        inspectionRouteId: "",
                        routeName: "",
                        inspectionRouteSelector: {},
                    };
                    $("#inspectionRouteSelector").val(null).trigger("change");
                }
            );
        })();
    },
    methods: {
        _initInspectionRouteSelect2: function () {
            $.fn.modal.Constructor.prototype.enforceFocus = function () {};
            $.fn.select2.defaults.set("width", "100%");
            this.inspectionRouteSelect2Info.inspectionRouteSelector = $(
                "#inspectionRouteSelector"
            ).select2({
                placeholder: "必填，请选择巡检路线",
                allowClear: true, //允许清空
                //multiple: true,//允许多选
                escapeMarkup: function (markup) {
                    return markup;
                }, // 自定义格式化防止xss注入
                ajax: {
                    url: "/callComponent/inspectionRouteManage/list",
                    dataType: "json",
                    delay: 250,
                    data: function (params) {
                        console.log("param", params);
                        var _term = "";
                        if (params.hasOwnProperty("term")) {
                            _term = params.term;
                        }
                        return {
                            routeName: _term,
                            page: 1,
                            row: 10,
                            communityId: this.$vc.getCurrentRegion()
                                .communityId,
                        };
                    },
                    processResults: function (data) {
                        return {
                            results: this._filterInspectionRouteData(
                                data.inspectionRoutes
                            ),
                        };
                    },
                    cache: true,
                },
            });
            $("#inspectionRouteSelector").on("select2:select", (evt) => {
                //这里是选中触发的事件
                //evt.params.data 是选中项的信息
                this.inspectionRouteSelect2Info.inspectionRouteId =
                    evt.params.data.id;
                this.inspectionRouteSelect2Info.routeName =
                    evt.params.data.text;
                var inspectionRouteIds = $("#inspectionRouteSelector").select2(
                    "val"
                );
                var routeIds = "";
                for (var i = 0; i < inspectionRouteIds.length; i++) {
                    routeIds += "," + inspectionRouteIds[i];
                }
                this.inspectionRouteSelect2Info.inspectionRouteId = routeIds.substr(
                    1
                );
            });

            $("#inspectionRouteSelector").on("select2:unselect", (evt) => {
                //这里是取消选中触发的事件
                //如配置allowClear: true后，触发
                var inspectionRouteIds = $("#inspectionRouteSelector").select2(
                    "val"
                );
                var routeIds = "";
                for (var i = 0; i < inspectionRouteIds.length; i++) {
                    routeIds += "," + inspectionRouteIds[i];
                }
                this.inspectionRouteSelect2Info.inspectionRouteId = routeIds.substr(
                    1
                );
                //this.inspectionRouteSelect2Info.routeName = '';
            });
        },
        _filterInspectionRouteData: function (_InspectionRoute) {
            var _tmpInspectionRoutes = [];
            for (var i = 0; i < _InspectionRoute.length; i++) {
                var _tmpInspectionRoute = {
                    id: _InspectionRoute[i].inspectionRouteId,
                    text: _InspectionRoute[i].routeName,
                };
                _tmpInspectionRoutes.push(_tmpInspectionRoute);
            }
            return _tmpInspectionRoutes;
        },
    },
};
</script>
<style>
</style>
